.user-settings-form {
  .form-container {
    width: 100%;

    .image-trigger {
      margin: 2em auto;
      cursor: pointer;
      max-width: 285px;
    }

    img.ui.image.avatar.user-profile-image {
      width: 100%;
    }

    img.edit-profile-icon {
      top: -25px;
      position: relative;
      display: block;
      margin-left: auto;
    }
  }

  h2.ui.header {
    margin-top: 0;
  }

  .contact-header {
    border-bottom: 1px solid $ui-divider;
    margin-bottom: 1.5em;
    margin-top: 2.5em;
    padding-bottom: 0.5em;
  }

  .submit-user-settings-btn {
    margin-top: 1em;
  }

  ::placeholder {
    color: $input-text-color !important;
  }
}
