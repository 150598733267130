@use "sass:map";
.admin-curator-requests {
  min-height: inherit;
  h1.curator-requests-header {
    margin-bottom: 16px;
  }

  .card-container {
    min-height: inherit;
  }
}

.ui.grid.curator-request {
  overflow-wrap: break-word;
  margin: 0;
  padding: 1em;
  .avatar-image {
    width: 60%;
    margin: 0 auto;
    display: block;
  }

  .username {
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
  }

  .institution-pos {
    margin-top: 0;
    margin-bottom: 0.25em;
    color: $ui-neutral-800;
    border-bottom: 1px solid $ui-neutral-200;
  }

  .description {
    margin-top: 12px;
    color: $ui-neutral-800;
  }

  .detail-header {
    font-weight: bold;
    margin-bottom: 0;
    color: $ui-neutral-800;
  }

  .ui.header.detail-header {
    font-size: 1.15em;
    margin-bottom: 0;
  }

  .role {
    font-weight: bold;
    color: $ui-neutral-800;
  }

  .description-column {
    overflow-wrap: break-word;
    border-right: 1px solid $ui-neutral-200;
  }

  .actions-column {
    .accept-decline-btn {
      display: flex;
      .button {
        width: 100%;
        padding: 0.5em 0.7em;
      }
    }
  }
}

.ui.grid.curator-request:not(:last-child):after {
  content: "";
  background: $ui-neutral-200;
  position: relative;
  bottom: -1em;
  left: 5%;
  height: 1px;
  width: 90%;
}

@media (max-width: map.get($breakpoints, lg)) {
  .curator-request {
    font-size: 12px;
    .actions-column {
      .accept-decline-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        .button {
          max-width: 150px;
          font-size: 12px;
          margin-bottom: 0.5em;
        }
      }
    }
  }
}