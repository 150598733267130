@use 'sass:map';
.explore-page {
  margin: 0 auto;
  max-width: $max-page-width;
  padding: 0 2em 5em;

  .search-container {
    width: 100%;
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    padding: 100px 0;
    justify-content: space-between;

    .ui.input.search-input {
      width: 100%;
      margin-left: 0;
      padding-right: 1em;
      input {
        padding-top: 18px;
        padding-bottom: 18px;
        border-radius: 0.7em;
      }
    }
    .button {
      border-radius: 0.7em;
    }
  }

  .published-transformations {
    margin: 0 auto;
    width: 100%;
    max-width: 984px;
    .published-transformations__header {
      font-size: 32px;
      margin-bottom: 1.5em;
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .explore-page {
    .search-container {
      max-width: 450px;
      padding: 56px 0;
      .ui.input.search-input {
        input {
          padding: 0.75em 0;
        }
      }
      .button {
        display: none;
      }
    }
  }
}

.page-wrapper.mobile-navbar-shown {
  .explore-page {
    .search-container {
      display: none;
    }
  }
}

@media (min-width: map.get($breakpoints, lg)) {
  .page-wrapper.mobile-navbar-shown {
    .explore-page {
      .search-container {
        display: flex;
      }
    }
  }
}