@use "sass:map";

.page-not-found {
  display: flex;
  align-items: center;
  min-height: $page-min-height;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
  max-width: $max-page-width;
  margin: 0 auto;

  .page-not-found__back-btn {
    position: absolute;
    top: 64px;
    left: 1em;
  }

  .page-not-found__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  }

  background-size: contain;
  background-image: $bg-img_blob3;
  background-repeat: no-repeat;
  background-position: center;

  h1 {
    margin: 0;
    font-size: 140px;
    font-weight: 800;
  }

  .subtitle {
    margin: 0;
    font-size: 28px;
    font-weight: 800;
  }

  .content {
    font-size: 20px;
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .page-not-found {
    .page-not-found__container {
      max-width: 350px;

      h1 {
        font-size: 100px;
      }

      .subtitle {
        font-size: 22px;
      }

      .content {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .page-not-found {
    .page-not-found__container {
      max-width: 200px;
      h1 {
        font-size: 64px;
      }

      .subtitle {
        font-size: 16px;
      }

      .content {
        font-size: 12px;
      }
    }
  }
}
