.become-curator-page {
  margin: 80px;

  .header-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .active-applications-header {

    }
    .new-application-btn {

    }
  }
}