@use 'sass:map';

.multi-month-picker {

  .months-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &>* {
      flex: auto;
      width: 25%;

      @media (max-width: map.get($breakpoints, md)) {
        width: 50%;
      }
    }
  }
}
