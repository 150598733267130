.profile-contributions {
  h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
  }

  h3 {
    color: #668779;
    margin: 4px 0 16px;
    font-size: 16px;
    font-weight: 400;
  }

  .contributions {
    padding-bottom: 2em;
    border-bottom: 1px solid $ui-neutral-600;
  }

  .curations {
    padding-top: 2em;
  }

}