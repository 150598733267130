.array-form-field {

  .array-form-field-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .field:first-child {
      flex: 1;
    }

    .field > .button {
      height: 100%;
    }
  }

  .segments {
    svg {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  // add margin on parent might be better
  .add-btn {
    margin-bottom: 1em !important;
  }
}
