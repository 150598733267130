@use 'sass:map';

.coming-soon {
  width: 100%;
  min-height: $page-min-height;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-origin: border-box;
  background-image: $bg-img_blob3;
  background-repeat: no-repeat;
  background-position: center bottom -800px;

  .container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 616px;

    .header {
      margin-bottom: 56px;
      padding-bottom: 0.3em;
      font-weight: 800;
      font-size: 160px;
      line-height: 69%;
      text-align: center;
      background: linear-gradient(0deg, #00CE7C -23.86%, #5564FA 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .subtitle {
      margin-bottom: 2em;
      border-top: 4px dashed #00CE7C;
      border-bottom: 4px dashed #00CE7C;
      text-transform: uppercase;
      padding: 0.8em 2em;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 7px;
      color: #00CE7C;
    }
  }
}


@media (max-width: map.get($breakpoints, md)) {
  .coming-soon {
    .container {
      .header {
        font-size: 80px;
      }

      .subtitle {
        font-size: 16px;
      }
    }
  }
}

@media (max-height: 900px) {
  .coming-soon {
    background-position: center bottom -850px;
  }
}