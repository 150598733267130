.bubble-form-field {
  .bubble-form-field-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .field:first-child {
      flex: 1;
    }

    .field > .button {
      height: 100%;
    }
  }

  .bubble-holder {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
}
