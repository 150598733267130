.terms-and-conditions {
  padding: 1em;
  margin-top: $default-vertical-page-margin;
  margin-bottom: $default-vertical-page-margin;
  h1 {
    text-align: center;
    margin-bottom: 2em;
  }
  .main-content {
    padding: 1em;
    font-size: 16px;
    margin: 0 auto;
    max-width: 900px;
  }
}