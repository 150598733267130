@use "sass:map";
.transformation-form-container {
  margin-top: $default-vertical-page-margin;
  margin-bottom: $default-vertical-page-margin;
  display: flex;
  justify-content: center;
  .transformation-form-view {
    width: 100%;
    max-width: $max-page-width;

    .form-view__back-btn {
      margin-left: 2em;
      margin-bottom: 2em;
    }

    .form-tab-container {
      width: 100%;

      // Message paragraphs in form view
      .ui.message p {
        font-weight: 400;
        padding-left: 2.5em;
      }

      // Fix alignment on bubble
      .ui.form .inline.fields {
        align-items: flex-start;

        //fix padding in last child of inline field
        .field:last-child {
          padding-right: 0;
        }
      }

      //fix alignment on popup icon
      .field.hint-container {
        display: flex;
        margin-bottom: 0;
      }
    }

    .ui.form.image-picker-container.transformation-card {
      margin-bottom: 2em;
    }

    .transformation-card {
      background: $primary-white;
      padding: 20px 24px;
      box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
      border-radius: 4px;
    }

    .biomass-type-container {
      justify-content: space-between;
    }

    .add-form-btn {
      align-self: flex-end;
      padding: 0 !important;
    }


    .image-picker-container {
      display: flex;
      flex-direction: column;
    }


    .scalability-form {
      .seasons-checkbox {
        justify-content: space-between;
        margin: 0.5em 0 1em;
      }

      .availability-select {
        justify-content: space-between;
        margin: 0.5em 0 1em !important;

        .field:last-child {
          padding-right: 0;
        }
      }

      .scale-production-container {
        justify-content: space-between;

        .no-padding-field.field {
          padding-right: 0;
        }
      }
    }

    .transformation-process-form {
      .applications-container {
        justify-content: space-between;
      }
    }

    .button-row {
      margin: 2em 0;

      .eight.wide.column {
        padding-right: 0;
        padding-left: 0;
      }

      .right-button {
        button {
          display: block;
          margin-left: auto;
        }
      }
    }

    .ui.form .field {
      label {
        display: inline-block;
        margin-right: 0.5em;
        margin-bottom: 1em;
      }
    }

    .documentary-evidence-form {
      .close-icon {
        float: right;
        cursor: pointer;

        &:hover {
          color: gray;
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, lg)) {
  .progress-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .vertical-step-container {
    .ui.fluid.vertical.steps.vertical-step {
      .step {
        font-size: 12px;
        .content {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  .progress-bar-container {
    flex-direction: row;
    .progress-bar__header {
      font-size: 14px;
    }
    .progress-bar__left {
      flex-direction: row;
      .ui.progress-bar {
        width: 120px;
      }
    }
  }

  .transformation-form-view {
    .form-tab-container {
      .ui.form.transformation-card {
        .inline.fields {
          .field {
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .transformation-form-container {
    .transformation-form-view {
      flex-direction: column;
    }
  }

}
