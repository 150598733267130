@use 'sass:map';
.signup-page {
  padding: 0 2rem;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;

  background-image: $bg-img_blob2;
  background-repeat: no-repeat;
  background-position: 50% 250%;


  .signup-container {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    margin-bottom: 90px;

    .signup-form {
      padding: 4em 3em;
      width: 100%;
      max-width: 630px;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 2em;
    }

    button.submit-signup-btn {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 1em 2em;
      width: 100%;
      max-width: 250px;
    }

    .links {
      text-align: center;
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .signup-page {
    background-position: 50% 185%;
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .signup-page {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    h1.ui-header {
      font-size: 24px;
    }
    .signup-container {
      margin: 3rem 0;
      .signup-form {
        padding: 2.5em 2em;
      }
    }
  }
}

