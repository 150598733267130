/*******************************
        Site Overrides
*******************************/

.ui.message {
  filter: drop-shadow(0px 2px 15px rgba(33, 33, 52, 0.1));
  padding-top: 1.35em;
  padding-bottom: 1.35em;
}

.ui.message p {
  font-size: 14px;
  // padding-left: 2.5em;
}

.ui.message .header {
  display: flex;
  align-items: center;
}

.ui.message .header span {
  padding-left: 0.5em;
}