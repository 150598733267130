@use 'sass:map';
.footer {
  background-color: $primary-black;
  min-height: 460px;
  width: 100%;

  background-image: $bg-img-footer;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $primary-white;

  .footer-delimiter {
    width: 100%;
    height: 100%;
    max-width: $max-page-width;
    padding: 0 2em;
  }

  .footer-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;

    .footer-items__section {
      display: flex;
      flex-direction: column;
      width: 35%;
      padding-top: 5em;
      padding-bottom: 5em;

      .section__header {
        font-size: 32px;
      }

      .section__text {
        font-size: 18px;
      }

      .login-signup-buttons {
        display: flex;
        flex-direction: row;
      }

      .section__btn {
        max-width: 110px;
        font-size: 16px;
        padding: 1em 1.5em;
      }
      .section__btn--white {
        background-color: white;
        color: $ui-neutral-800;
      }
      .section__btn--white:hover {
        background-color: #e6e6e6;
      }
      .section__btn--publish {
        max-width: 150px;
      }

    }

    .footer-items__bar {
      padding-top: 1.75em;
      padding-bottom: 1.75em;
      display: flex;
      align-items: center;
      border-top: 2px solid white;
      position: relative;

      .bar__logo {
        position: absolute;
        left: calc(50% - 150px / 2);
      }

      .bar__socials {
        position: absolute;
        right: 0;

        a {
          margin-left: 2em;
          color: white;
        }

        svg {
          width: 25px;
          height: 25px;
        }
        svg:not(:last-child) {
          padding-right: 2em;
        }
      }

      .bar__links-container {
        display: flex;
        .bar__links {
          display: flex;
          flex-direction: column;
          margin-right: 2em;
          a, span {
            color: $primary-white;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;
          }

          a:hover, span:hover {
            text-decoration: underline;
          }
        }
      }

    }
  }
}

.footer.footer--basic {
  min-height: unset;
  background-image: none;
  background-color: $page-background;
  color: black;

  .footer-items {
    border-top: 2px solid $transformar-green;
    .footer-items__bar {
      border-top: none;
    }

    .bar__links-container {
      .bar__links {
        a, span {
          color: $ui-navbar-links;
        }
      }
    }

    .bar__socials {
      a, span {
        color: $ui-navbar-links;
      }
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .footer {
    .footer-delimiter {
      .footer-items__section {
        padding-left: 2em;
        width: 80%
      }
      .footer-items__bar {
        padding-left: 2em;
        padding-right: 2em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .bar__links-container {
          order: 2;
          flex-direction: column;
          margin-bottom: 2em;
        }
        .bar__logo {
          position: static;
          margin-bottom: 2em;
          order: 1;
        }
        .bar__socials {
          position: static;
          order: 3;
          a {
            margin: 0;
          }
          a:not(:last-child) {
            padding-right: 2em;
          }
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .footer {
    .footer-delimiter {
      .footer-items {
        .footer-items__section {
          padding: 4em 2em;
          .login-signup-buttons {
            flex-direction: column;
            a {
              margin-bottom: 1em;
            }
          }
        }
        .footer-items__bar {
          align-items: center;
          .bar__links-container {
            .bar__links {
              margin: 0;
              a, span {
                text-align: center;
                margin-bottom: 0.25em;
              }
            }
          }
        }
      }
    }
  }
}
