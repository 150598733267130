.user-not-found-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 120px - 3.85em - 1rem - 10em);

  h2 {
    margin-top: 5em;
    text-align: center;
  }

}