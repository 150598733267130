@use "sass:map";
.about-page {
  margin: $default-vertical-page-margin 0 0;

  .first-section {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    margin-bottom: 120px;

    .first-section-header {
      max-width: 40%;
      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 2em;
        border-bottom: 2px solid $ui-navbar-links;
        letter-spacing: 5px;
      }
    }

    .sprinkler-container {
      max-width: 60%;
      img {
        position: relative;
        width: 100%;
        z-index: -1;
      }
    }

  }

  .second-section {
    background-color: white;
    padding-bottom: 72px;

    .second-section-delimiter {
      padding: 0 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .second-section__crops-logo-container {
        padding: 2em;
        width: 60%;

        .crops-logo {
          width: 100%;
          max-width: 380px;
          position: relative;
          display: block;
          top: -48px;
          filter: drop-shadow(0px 100px 200px rgba(33, 52, 44, 0.3));
        }
      }

      .second-section__text {
        width: 40%;
        max-width: 480px;
        padding: 2em;
        margin-top: 1em;

        h3 {
          font-size: 32px;
          margin-bottom: 2em;
        }

        .second-section__explanation {
          width: 100%;

          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .fourth-section.faq {
    padding: 0 2rem;
    margin-bottom: 120px;
    h2 {
      font-size: 32px;
    }

    .subheader {
      font-size: 16px;
      color: #668779;
    }

    .faq-container {
      margin: 3em 0;
    }

    .pdf-download-btn {
      margin-top: 1em;
    }
  }

  .fifth-section.contact {
    background-color: white;
    text-align: center;
    padding: 125px 2rem;

    h2 {
      font-size: 32px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 16px;
      color: #668779;
      font-weight: 400;
      margin: 0;
    }

    .contact-items-grid {
      margin: 5em 0 0;
      padding: 1em 3em;

      h3 {
        font-size: 24px;
        font-weight: 700;
      }

      .column {
        .mail-btn-container {
          display: block;
        }
        .mail-btn {
          padding-top: 0.8em;
          padding-bottom: 0.8em;
          width: 100%;
          max-width: 225px;
        }
      }

      .column:first-child {
        border-right: 2px solid $ui-neutral-200;
      }

      .contact-item__socials {
        color: $ui-text-color;
        font-size: 40px;

        a {
          color: inherit;
        }

        svg {
          padding: 0 0.5em;
        }
      }
      button {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }

  @media (max-width: map.get($breakpoints, lg)) {
    .first-section {
      .first-section-header {
        h1 {
          font-size: 32px;
        }
        h2 {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: map.get($breakpoints, md)) {
    .first-section {
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      .first-section-header {
        text-align: center;
        width: 100%;
        max-width: 500px;
        h1 {
          font-size: 24px;
        }
        h2 {
          font-size: 12px;
          margin-bottom: 72px;
        }
      }
      .sprinkler-container {
        width: 100%;
        max-width: 350px;
        position: relative;
      }
    }

    .second-section {
      .second-section-delimiter {
        flex-direction: column;
        align-items: center;
        .second-section__crops-logo-container {
          order: 2;
          width: 100%;
          max-width: 350px;
          .crops-logo {
            margin: 0 auto;
            top: 0;
          }
        }
        .second-section__text {
          order: 1;
          width: 100%;
          max-width: 500px;
          text-align: center;
          h3 {
            margin-bottom: 1em;
          }
          .second-section__explanation {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }

    .third-section {
      .organization-section__card:first-child {
        margin-bottom: 48px;
      }
    }

    .fourth-section.faq {
      .faq-row-wrapper {
        padding: 1em 2em;
      }
    }
    .fifth-section.contact {
      padding: 72px 2rem;
      .contact-items-grid {
        margin-top: 2em;
        padding: 1em 0;
        .column:first-child {
          border-right: none;
          .contact-item__socials {
            margin-bottom: 1em;
          }
        }
      }
    }
  }

  @media (max-width: map.get($breakpoints, sm)) {
    .fourth-section.faq {
      .faq-row-wrapper {
        .faq-body {
          .faq-row {
            .row-title {
              font-size: 16px;
            }
            .row-content {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .fifth-section.contact {
      .contact-items-grid {
        .column {
          .mail-btn {
            font-size: 0.9rem;
          }
        }
        .column:first-child {
          border-right: none;
          .contact-item__socials {
            margin-bottom: 1em;
            font-size: 30px;
          }
        }
      }
    }
  }
}

.faq-row-wrapper {
  padding: 2em 5em;
  .faq-body {
    .faq-row {
      border-bottom: 1px solid $ui-neutral-100;
      .row-title {
        color: $ui-neutral-800;
        font-weight: 600;
      }

      .row-content {
        padding: 0.5em 0;
        .tabs-list-title {
          margin-bottom: 0;
        }

        ol {
          margin: 0.5em 0 1em;
        }

        a {
          color: $transformar-blue;
        }
      }
    }
  }
}

.organization-section {
  margin-top: 80px;

  h3 {
    text-align: center;
    font-size: 32px;
  }

  .subtitle {
    text-align: center;
    color: #668779;
    font-size: 16px;
    margin: 0 auto 72px;
    max-width: 70%;
  }

  h4 {
    text-align: center;
    font-size: 24px;
  }

  a {
    color: inherit;
  }

  .organization-section__cards {
    padding: 0 3rem;
  }

  .organization-section__card {
    padding: 0 2em 3em;
    margin: 0 auto 8em;
    width: 100%;
    max-width: 600px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }

    h4 {
      font-size: 16px;
      margin: 0 0 1em;
      text-transform: uppercase;
      font-weight: 800;
      text-align: center;
    }

    p {
      margin: 0 auto;
      width: 100%;
      max-width: 380px;
    }
  }

  .organization-section__card:first-child {
    margin-bottom: 48px;
  }

  .organization-section__card.usfq-card {
    padding: 3em;

    img {
      padding: 2em 0;
    }

    p {
      text-align: center;
    }
  }
}

