.ui.pagination.menu.pagination-bar {
  width: 100%;
  justify-content: center;
  .active.item {
    background-color: white;
    color: $transformar-blue;
    font-weight: 800;
  }
  .item:hover {
    background-color: $ui-neutral-100;
    color: $transformar-blue;
  }
}

.infinite-scroll-loader {
  height: 80px;
  position: relative;

  .ui.inline.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
