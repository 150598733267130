.back-button {
  // remove button default styles
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;

  color: $transformar-blue;

  .back-button__text {
    color: inherit;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: 0.75em;
    font-size: 12px;
  }
}

.back-button:hover {
  color: #1f2dd4;
  cursor: pointer;
}