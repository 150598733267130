@use "sass:map";
.user-settings-page {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 72px 2rem;

  .user-settings-card {
    width: 100%;
    max-width: 775px;
    padding: 2em 3em;
  }

  @media (min-width: map.get($breakpoints, xl)) {
    .user-settings-card {
      max-width: 950px;
      padding: 3em 7em;
    }
  }

  @media (max-width: map.get($breakpoints, md)) {
    .user-settings-card {
      padding: 2em;
      .user-settings-form {
        .form-container {
          .image-trigger {
            max-width: 225px;
          }
        }
      }
    }
  }

  @media (max-width: map.get($breakpoints, sm)) {
    .user-settings-card {
      padding: 2em;
      .user-settings-form {
        .form-container {
          .image-trigger {
            max-width: 200px;
          }
        }
      }
    }
  }

}