@use "sass:map";
.transformation-page {
  margin-top: $default-vertical-page-margin;
  margin-bottom: $default-vertical-page-margin;
  padding: 0 2rem;

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .back-btn {
    padding: 1em 0;
  }

  .pdf-btn {
    display: block;
    margin: 0 0 0 auto;
    padding-bottom: 1.5em;
    //padding-right: 2em;

    background-color: white;
    color: $transformar-blue;
  }

  .pdf-btn:hover {
    color: #1f2dd4;
    background-color: white;
  }

  .pdf-btn.pdf-btn--mobile {
    margin: 0;
    padding: 0.7em 1.5em;
    background-color: $page-background;
    display: none;
  }

  .pdf-btn.pdf-btn--mobile:hover {
    background-color: $page-background;
  }

  .transformation-header {
    padding: 2em;

    .row:not(:last-child) {
      border-bottom: 1px solid $ui-neutral-100;
    }

    h1 {
      font-size: 32px;
      border-right: 1px solid $ui-neutral-100;
    }

    h1:first-letter {
      text-transform: capitalize;
    }

    .origin {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;

      .from {
        font-weight: 800;
      }
    }

    .published-by {
      font-size: 14px;

      span.header {
        font-weight: 700;
      }

      .author {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .author-avatar {
        a {
          display: flex;
          align-items: center;
          color: $ui-neutral-800;
        }

        a:hover {
          color: $transformar-green;
        }
      }
    }
  }

  .transformation-header.transformation-header--print {
    display: none;
    margin: 2em 0 2.25em;

    .ui.grid {
      width: 50%;

      h1 {
        border: none;
      }

      .row {
        border: none;
      }

      .bookmark-column {
        display: none;
      }

      .crowdsourcing-likes {
        display: none;
      }
    }
  }

  .column.transformation-sidebar {
    .sidebar-container {
      position: sticky;
      top: 1em;
    }

    .menu-card-container {
      margin-top: 10px;

      .ui.fluid.secondary.transformation-menu {
        font-size: 16px;
        padding: 0.5em 1em;
        margin: 0;

        .active.item {
          background-color: $primary-white;
          color: $transformar-green;
          font-weight: bold;
        }

        .item:not(:last-child):after {
          content: "";
          background: $ui-neutral-100;
          position: absolute;
          bottom: 0;
          left: 5%;
          height: 1px;
          width: 90%;
        }

        a.item:hover {
          background-color: $primary-white;
          color: $transformar-green;
        }
      }
    }
  }

  .transformation-view-container {
    padding: 1.5em;

    .column > h3 {
      font-size: 18px;
      font-weight: 700;
    }

    .column > h3 + p {
      font-size: 14px;
    }

    .transformation-image {
      width: 100%;
      max-height: 250px;
      object-fit: cover;
    }

    .row:not(:last-child) {
      border-bottom: 1px solid $ui-neutral-200;
    }

    .evidence_url {
      color: inherit;
      display: block;
      margin-bottom: 1em;

      &.warning {

        &:before {
          content: '*';
          margin-right: 0.5em;
          color: $warning-red;
        }
      }
    }

    .evidence_url:hover {
      color: $transformar-green;
    }
  }
}

@media (max-width: map.get($breakpoints, lg)) {
  .transformation-page {
    .transformation-header {
      .published-by {
        .author {
          .author-avatar {
            a {
              img {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .transformation-page {
    .pdf-btn.pdf-btn--mobile {
      display: block;
    }
    .transformation-header {
      padding: 2em 4em;
      .published-by {
        .author {
          .author-avatar {
            a {
              img {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .column.transformation-sidebar {
      .sidebar-container {
        position: static;
      }
      .menu-card-container {
        display: none;
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .transformation-page {
    padding: 0 1em;
    .transformation-header {
      padding: 2em;
      .published-by {
        .author {
          .author-avatar {
            a {
              img {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// Print styles
@media print {
  .computer.only.row.computer-row-navbar {
    display: none;
  }
  .tablet.only.mobile.only.row.tablet-mobile-row-navbar {
    .right.menu {
      display: none;
    }
  }

  .transformation-page {
    .back-btn {
      display: none;
    }

    .pdf-btn.pdf-btn--mobile {
      display: none;
    }

    .ui.grid {
      .transformation-sidebar {
        display: none;
      }

      .eleven.wide.column.transformation-view-column {
        padding: 1em 3em;
        width: 100% !important;
      }
    }

    .transformation-header.transformation-header--print {
      display: block;
    }
  }

  .footer {
    margin-top: 4em;
    .footer-items {
      .footer-items__bar {
        .bar__logo {
          align-self: center;
        }

        .bar__socials {
          display: none;
        }

        .bar__links-container {
          .bar__links {
            display: none;
          }
        }
      }
    }
  }
}
