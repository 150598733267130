.transformation-like-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 30px;
  }

  span {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 700;
    color: $ui-neutral-400;
  }
}