.become-curator-container {
  margin-top: 112px;

  .submit-btn {
    display: block;
    margin: 0 0 0 auto;
    padding: 0.75em 4em;
  }

  .back-to-applications {
    color: $transformar-blue;
  }
}