@use 'sass:map';
.user-profile-page {
  width: inherit;
  display: block;
  margin: 0 auto;
  max-width: $max-page-width;
  padding: 72px 2rem;

  min-height: inherit;
}

@media (max-width: map.get($breakpoints, lg)) {
  .user-profile-page {
    .side-profile-column {
      .user-side-profile {
        margin: 0 auto 72px;
        max-width: 600px;
        padding: 5em;
        .side-profile-main {
          align-items: center;
          .side-profile-main__position {
            font-size: 14px;
          }
        }
        .user-side-profile__bio {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          max-width: 450px;
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .user-profile-page {
    .side-profile-column {
      .user-side-profile {
        max-width: 450px;
        padding: 3em;
      }
    }
  }
}