.yes-no-form-field {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
  justify-content: space-between;

  div.radio-container {
    display: flex;
    flex-direction: row;
  }
}
