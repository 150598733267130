@use 'sass:map';

.user-side-profile {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  padding: 3em;

  .side-profile-main {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $ui-card-border;

    .edit-profile-icon {
      img {
        top: -25px;
        position: relative;
        display: block;
        margin-left: auto;
      }
    }

    .side-profile-main__avatar {
      margin: 0 auto;
      width: 100%;
      max-width: 283px;
      box-shadow: 0 2px 15px rgba(33, 33, 52, 0.1);
    }
    .side-profile-main__name {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .side-profile-main__position {
      margin-bottom: 1em;
    }
  }

  .user-contact {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: grid;
    grid-template-columns: auto min-content;
    justify-content: center;
    border-bottom: 1px solid $ui-card-border;
    gap: 5px 5px;

    color: $ui-text-color-green-bold;

    .user-contact__item {
      padding: 8px;
      border: 2px solid $ui-text-color-green-bold;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-weight: 700;

      svg {
        width: 15px;
        height: 15px;
        padding-right: 8px;
      }
    }

    .user-contact__item--mail {
      grid-column: span 2;
      justify-self: center;
    }
  }

  div:last-child {
    border: none;
  }

  p:last-child {
    border: none;
  }

  .user-side-profile__bio {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid $ui-card-border;
    text-align: left;
    font-size: 14px;
    margin-bottom: 0;
  }

  .user-socials {
    padding-top: 2em;
    display: flex;
    justify-content: space-evenly;
    svg {
      width: 25px;
      color: $ui-text-color;
    }

    svg:hover {
      color: $ui-text-color-green-bold
    }
  }
}