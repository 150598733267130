@use 'sass:map';
.home-page {
  display: flex;
  justify-content: center;
  width: 100%;

  .home-page__header-secondary {
    font-weight: 700;
    font-size: 32px;
  }

  .home-page__subheader {
    font-size: 16px;
    font-weight: 400;
  }

  .home-page__text {
    font-size: 14px;
    font-weight: 400;
  }

  .home-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .home-page__first-section {
      width: 100%;
      position: relative;
      color: white;

      .first-section__header {
        padding-left: 2em;
        padding-top: 200px;
        display: flex;
        flex-direction: column;
        width: 50%;
        min-height: 800px; //background height

        h1 {
          font-weight: 800;
          font-size: 64px;
          max-width: 100%;
        }

        p {
          font-size: 24px;
          font-weight: 600;
        }

        .publish-btn {
          margin-top: 0.5em;
          max-width: 155px;
          padding: 0.8em 1em;
          font-size: 24px;
          border-radius: 0.3em;
        }
      }

      .first-section__hero {
        padding: 0 2em;
        font-size: 24px;
        text-align: center;
        font-weight: 800;
        max-width: 950px;
        margin: 0 auto 2em;
      }
    }

    // Background image
    .home-page__first-section:after {
      content: '';
      display: block;
      position: absolute;
      top: -(calc($navbar-height + 25px));
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: -1;
      background: $bg-img-landing no-repeat right 50%;
      background-size: cover;
    }

    .home-page__second-section {
      display: flex;
      justify-content: center;
      width: 100%;
      //background-color: $primary-white;

      .second-section-delimiter {
        width: 100%;
        max-width: $max-page-width;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
      }

      .second-section__header {
        margin-top: 72px;
      }

      .second-section__subheader {
        width: 50%;
      }

      .second-section__images {
        margin-top: 64px;
        margin-bottom: 136px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .second-section-grid {
          margin: 0;
        }

        .column {
          display: flex;
          justify-content: center;
        }

        a {
          color: inherit;
        }

        .image-card {
          max-width: 390px;
          height: 370px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .image-card__header {
            margin: 0;
          }

          .image-card__image {
            width: 100%;
          }

          .image-card__description {
            padding: 1em;
          }
        }
      }
    }

    .home-page__third-section {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $primary-white;
      overflow: hidden;

      .third-section-delimiter {
        padding-top: 112px;
        padding-bottom: 228px;
        max-width: $max-page-width;
        display: flex;
        flex-direction: column;
        align-items: center;

        background-image: $bg-img_blob3;
        background-repeat: no-repeat;
        background-position: center bottom -1054px;
        background-size: 110%;

        .third-section__how-it-works {
          text-align: center;
          padding: 24px 0;
          position: relative;

          .how-it-works__subheader {
            display: inline-block;
            max-width: 40%;
          }
        }

        // border
        .third-section__how-it-works::before {
          content: "";
          position: absolute;
          left: calc(50% - 50px);
          top: 0;
          height: 100%;
          width: 100px;
          border-top: 2px solid $ui-neutral-200;
        }

        .third-section__icons {
          margin: 0;
          padding: 2em;
          position: relative;

          .icon-column {
            text-align: center;

            img {
              margin: 0 auto;
              width: 50px;
            }
          }
        }

        // bottom icons border
        .third-section__icons:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: calc(50% - 50px);
          height: 100%;
          width: 100px;
          border-bottom: 2px solid $ui-neutral-200;
        }

        .third-section-steps {
          width: 100%;
          padding: 0 5em;
          margin: 5em 0 0;

          .buttons-container {
            display: flex;
          }

          .item__image {
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
            display: block;
            filter: drop-shadow(0px 30px 60px rgba(33, 52, 44, 0.15));
          }

          .column.step {
            max-width: 384px;
            padding: 2em;
          }

          .column.step.step--right {
            margin-left: auto;
          }

          .column.step.step--left {
            margin-right: auto;
            text-align: right;
          }

          .column.step.step--right.first-step {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item__btn {
              width: fit-content;
            }

            .item__btn:first-child {
              margin-right: 1em;
            }
          }
        }
      }
    }

    .home-page__fifth-section {
      .organization-section__card {
        padding: 1em;
        align-items: center;
        justify-content: space-evenly;
        display: flex;
      }
    }
  }
}

.page-wrapper.mobile-navbar-shown {
  .home-page {
    .home-page__first-section:after {
      top: 0;
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .home-page {
    .home-page-container {
      .home-page__first-section {
        .first-section__header {
          min-height: 700px;
          width: 100%;
          h1 {
            max-width: 90%;
            font-size: 56px;
          }
          p {
            font-size: 20px;
          }
          .publish-btn {
            font-size: 18px;
          }
        }

        .first-section__hero {
          max-width: 80%;
          font-size: 20px;
        }
      }
      .home-page__third-section {
        .third-section-delimiter {
          background-position: center bottom -550px;

          .third-section-steps {
            .column, .column.step, .column.step.step--left, .column.step.step--right {
              text-align: left;
              margin: 1.5em auto !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .home-page {
    .home-page-container {
      .home-page__first-section {
        .first-section__header {
          min-height: 500px;
          padding: 96px 2em 0;
          h1 {
            font-size: 40px;
          }
        }
        .first-section__hero {
          padding: 0;
          max-width: 65%;
          font-size: 18px;
        }
      }

      .home-page__second-section {
        .second-section__images {
          margin-bottom: 66px;
        }
      }

      .home-page__third-section {
        .third-section-delimiter {
          background-position: center bottom -335px;
          padding-bottom: 80px;
          padding-top: 80px;
          .third-section__how-it-works {
            .how-it-works__subheader {
              margin-top: 1em;
              max-width: 70%;
            }
          }
          .third-section-steps {
            margin-top: 1em;
          }
        }
      }

      .home-page__fifth-section {
        .organization-section__card {
          max-width: 80%;
          flex-direction: column;
          padding-bottom: 5em;
        }
      }
    }
  }
}

@media (min-width: map.get($breakpoints, lg)) {
  .page-wrapper {
    .home-page {
      .home-page-container {
        .home-page__first-section:after {
          top: -(calc($navbar-height + 25px));
        }
      }
    }
  }
}
