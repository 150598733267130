@use "sass:map";
.ui.grid.navbar {
  margin: 0;
  justify-content: center;

  .right.menu.navbar-right-menu {
    align-items: center;
  }

  .computer-row-navbar.computer.only.row {
    z-index: 1;
    padding-bottom: 0;
    padding-top: 2em;
    max-width: $max-page-width;
  }

  .tablet-mobile-row-navbar.tablet.only.mobile.row {
    padding-bottom: 0;
    padding-top: 2em;
    z-index: 1;

    .bars {
      cursor: pointer;
      font-size: 24px;
    }

    .mobile-navbar-content {
      color: black;
      background-color: $page-background;

      .column {
        padding: 2em 1em;
        margin-top: 2em;
        margin-bottom: 2em;
      }

      .locale-icon {
        padding: 0 1em;
        margin-bottom: 2em;
        font-size: 16px;
      }

      .navbar-mobile-menu {
        border: none;
        background-color: $page-background;
        box-shadow: none;
        margin: 2em 0;
      }

      .login-signup {
        width: 100%;
        margin: 0 auto;
        max-width: 250px;
        display: flex;
        justify-content: center;
        .ui.button {
          width: 100%;
        }
        .ui.button:not(:last-child) {
          margin-right: 1em;
        }
        .login-btn {
          background-color: white;
          color: $ui-neutral-800;
          font-weight: 700;
        }
      }

      .search-input {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 300px;
        input {
          width: 100%;
          background-color: white;
          border-radius: 2em;
        }
      }

      .navbar-mobile-menu-item {
        font-size: 16px;

        .menu-item-content {
          padding: 1em 0 0;
          font-size: 14px;

          a, span {
            display: block;
            color: inherit;
            cursor: pointer;
          }

          a:not(:last-child), span:not(:last-child) {
            margin-bottom: 1em;
          }
        }
      }
      .navbar-mobile-menu-item:before {
        background: $ui-neutral-200;
        height: 0.5px;
      }

      .sign-out {
        padding: 1em 0;
        font-size: 14px;
        .content {
          cursor: pointer;
        }
      }
      .sign-out:before {
        height: 0.5px;
        background-color: $ui-neutral-200;
      }
    }

  }

  .ui.menu.menu-navbar {
    font-size: 14px;
    background-color: transparent;
    border: none;
    box-shadow: none;

    .item {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 0.75em;
    }

    .item.search-bar {
      .search-input.ui.left.icon.input {
        transition: width 700ms, opacity 700ms;
      }
    }

    .item.search-bar.removed {
      .search-input.ui.left.icon.input {
        width: 0;
        opacity: 0;
      }
    }

    .item.search-bar.transitioned {
      .search-input.ui.left.icon.input {
        width: 300px;
        opacity: 1;
      }
    }

    .item.search-icon.removed {
      display: none;
    }

    .item.search-icon {
      transition: scale 200ms;
    }

    .item.search-icon:hover {
      scale: 1.2;
      cursor: pointer;
    }

    .navbar-link {
      font-weight: bold;
      color: $primary-black;
    }

    .publish-btn:hover {
      font-weight: bold;
    }

    .navbar-link:hover {
      color: $transformar-green;
      text-decoration-line: underline;
    }

    .green-logo {
      width: 100%;
      margin: 0;
    }

    .navbar__login-btn {
      color: $ui-navbar-links;
      background-color: $ui-neutral-200;
    }

    .navbar__login-btn:hover {
      background-color: #c4ccc9;
    }
  }

  .ui.menu.menu-navbar.menu-navbar--custom-inverted {
    color: white;

    .locale-icon {
      color: white;
    }

    .item.search-icon {
      .search.large.icon {
        color: white;
      }
    }

    .item.search-bar {
      input {
        border: none;
        opacity: 0.9;
      }
    }

    .bars {
      color: white;
    }

  }

  .navbar__dropdown-container {
    color: #181826;
    font-weight: bold;

    a {
      color: inherit;
    }

    .menu.transition {
      min-width: 278px;
    }

    .navbar__dropdown_item {
      color: $ui-neutral-800;
      overflow-wrap: break-word;
      white-space: normal;


      h3 {
        font-size: 16px;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
      }
    }

    .navbar__dropdown_item:after:not(:last-child) {
      content: "";
      background: #F6F9F8;
      position: absolute;
      bottom: 0;
      left: 5%;
      height: 1px;
      width: 90%;
    }
  }

  .ui.button.navbar__dropdown-container:hover {
    .menu.transition {
      visibility: visible;
      display: block;
    }
  }

  .ui.button.navbar__dropdown-container.custom-inverted {
    background-color: transparent;
    color: white;
  }

  .ui.button.navbar__dropdown-container.custom-inverted:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .ui.button.dropdown {
    background-color: $page-background;
  }

  .ui.button.dropdown:hover {
    background-color: $ui-card-border;
  }
}

.navbar.user-navbar {
  .ui.avatar.image {
    margin-right: 0.75em;
  }

  .right.menu.navbar-right-menu {
    .menu.transition.visible {
      top: 35px;
    }
  }

  .ui.dropdown {
    .dropdown.icon {
      margin-left: 0.5em;
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .ui.grid.navbar {
    .ui.menu.menu-navbar {
      .green-logo {
        width: 85%;
      }
    }
  }
}

