@use "sass:map";
.ui.grid.admin-dashboard-page {
  margin: $default-vertical-page-margin 0;
  padding: 0 2rem;
}

.admin-dashboard-card-container {
  margin-top: 52px; // 36 + 16
  .ui.secondary.vertical.admin-dashboard-menu {
    font-size: 16px;
    padding: 0 2em;

    .active.item {
      background-color: $primary-white;
      color: $transformar-blue;
      font-weight: bold;
    }

    a.item:hover {
      background-color: $ui-hover-menu-item;
      color: $transformar-blue;
    }
  }
}

@media (max-width: map.get($breakpoints, lg)) {
  .ui.grid.admin-dashboard-page {
    .menu-column {
      .admin-dashboard-card-container {
        margin-top: 0;
        margin-bottom: 24px;
      }
    }
  }
}
