.progress-bar-container {
  margin-top: 1em;
  background: $primary-white;
  width: 100%;
  padding: 0.75em 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
  border-radius: 4px;

  .progress-bar__header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .progress-bar__left {
    display: flex;
    align-items: center;
  }

  .progress-bar__page {
    color: $transformar-blue;
    font-weight: 700;
    padding-right: 0.5em;
    font-size: 12px;
  }

  .ui.progress.progress-bar {
    margin-bottom: 0;
    width: 80px;

    .bar {
      height: 0.75em;
    }
  }

}
