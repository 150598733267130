$text-color: #ff00ff;
$primary-text: black;
$secondary-text: #ffffffe6;
$primary-color: grey;
$secondary-color: #4183c4;
$tertiary-color: white;
$ui-divider: rgba(128, 128, 128, 0.7);
$input-text-color: rgba(0, 0, 0, .6);


$page-background: #f6f9f8;
$transformar-green: #26d07c;
$transformar-green-secondary: #98f5bf;
$transformar-blue: #5564fa;
$primary-black: #222223;
$secondary-black: #212721;
$glass-silver: #c3d7ee;
$secondary-white: #edf5f0;
$primary-white: #ffff;
$warning-red: #ff595e;
$ui-text-color: #8ea99d;
$ui-header-text-color: #21342c;
$ui-card-border: #eaefed;
$ui-card-border-shadow: rgba(33, 33, 52, 0.1);
$ui-neutral-800: #324d41;
$ui-neutral-600: #668779;
$ui-neutral-400: #a5bab1;
$ui-neutral-200: #dce4e1;
$ui-neutral-150: #eaefed;
$ui-neutral-100: #f6f9f8;
$ui-text-color-green-bold: #4a6a5c;
$ui-navbar-links: #181826;
$ui-hover-menu-item: #f9fafb;
$ui-primary-600: #38cf92;

// responsive breakpoints
$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1240px,
        xxl: 1400px
);

$max-page-width: 1240px;

// Dimensions
$footer-height: 90px; // reference values
$navbar-height: 60px; // reference values

$default-vertical-page-margin: 72px;

$page-min-height: calc(100vh - $footer-height - $navbar-height);
$bg-img_blob3: url("../assets/blobs/blob_3.webp");
$bg-img_blob2: url("../assets/blobs/blob_2_rotated.webp");
$bg-img-blob_photo_welcome: url("../assets/blobs/blob_photo_welcome.webp");
$bg-img-footer: url("../assets/images/footer-background.webp");
$bg-img-landing: url("../assets/images/landing.webp");
