@use 'sass:map';
.my-contributions-page {
  padding-top: 82px;
  padding-bottom: 82px;

  .add-btn:hover {
    font-weight: 700;
  }

  .my-contributions-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 72px;

    h1 {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }

  .contributions-list {
    h2 {
      margin-bottom: 1em;
    }

    .contributions-group:not(:last-child) {
      margin-bottom: 5em;
    }
  }

  @media (max-width: map.get($breakpoints, lg)) {
    .my-contributions-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      h1 {
        margin: 0 0 0.5em;
      }
    }

    .ui.button.button.add-btn {
      display: block;
      position: relative;
    }
  }

}



