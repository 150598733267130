@use "sass:map";
.image-asset-picker {
  .ui.small.image-asset {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .ui.grid.asset-picker-grid {
    padding: 1em 3em;
  }

  .image-asset {
    cursor: pointer;
  }

  .image-asset:not(.selected-image):hover {
    opacity: 0.5;
  }

  .selected-image {
    border: 3px solid $transformar-green;
  }

  .ui.ribbon.selected-label {
    position: absolute;
    top: 1rem;
    left: 0;
    z-index: 2;
  }
}


.transformation-shown-image-container {
  width: 50%;
  position: relative;
  cursor: pointer;

  .transformation-shown-image {
    width: 100%;
    //max-height: 250px;
    height: auto;
    aspect-ratio: 2 / 1.3;
    object-fit: cover;
    border-radius: 0.5em;
  }

  .image-text {
    //background-color: rgba(88, 88, 88, 0.28);
    color: white;
    display: none;
    margin: 0 auto;
    bottom: 50%;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
  }
}

.transformation-shown-image-container:hover {
  .transformation-shown-image {
    filter: brightness(0.5);

  }

  .image-text {
    display: block;
  }
}


.choose-image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  aspect-ratio: 2 / 1.3;
  background-color: $ui-neutral-150;
  border-radius: 0.5em;
  cursor: pointer;

  img {
    width: 15%;
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    color: #C0CFC9;
    max-width: 50%;
    text-align: center;
    font-weight: 700;
  }
}

.choose-image-placeholder:hover {
  background-color: hsl(150, 2%, 90%);
}

@media (max-width: map.get($breakpoints, md)) {
  .image-asset-picker {
    .ui.grid.asset-picker-grid {
      padding: 1em 2em;
      .selected-label {
        top: 0.5rem;
        padding-left: calc(0.5rem + 1.2em);
      }
      .column {
        padding: 0.5rem;
      }
    }
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .image-asset-picker {
    .ui.grid.asset-picker-grid {
      padding: 1em 0;
      .selected-label {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}