@use 'sass:map';
.ui.segment.general-transformation-item {
  margin-bottom: 1em;
  background-color: $primary-white;
  //height: 175px;
  color: $primary-text;

  .ui.grid.transformation-grid {
    min-height: 220px;

    .delete-button-container {
      button {
        display: block;
        margin: 0 auto;
      }
    }

  }

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }

  h2:first-letter {
    text-transform: capitalize;
  }

  p.subtitle, p {
    font-size: 12px;
  }

  p.subtitle:first-letter {
    text-transform: capitalize;
  }

  a {
    color: $primary-text;
  }

  a:hover {
    color: $transformar-green;
  }

  div.clickable-header {
    color: $primary-text;

    &:hover, &:active, &:focus {
      color: $transformar-green;
      cursor: pointer;
    }
  }

  .transformation-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }

  .user-avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      font-size: 10px;
      padding-right: 1em;
    }

    a {
      color: $primary-text;
    }

    a:hover {
      color: $transformar-green;
    }
  }

  .transformation-likes {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .column {
      padding: 0;
    }

    .bookmark-row {
      padding-bottom: 0.5em;
      padding-top: 0.5em;
      border-bottom: 1px solid $ui-neutral-200;
    }

    .crowdsourcing-row {
      padding-top: 1em;
    }

    img.bookmark {
      max-width: 40px;
    }

    .ui.grid {
      margin: 0;
    }
  }

  .main-content {
    border-right: 1px solid $ui-neutral-200;
  }
  .header {
    margin-right: 1rem;
    border-bottom: 1px solid $ui-neutral-200;
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .ui.segment.general-transformation-item {
    .ui.grid.transformation-grid {
      .main-content {
        .user-avatar {
          img {
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .ui.segment.general-transformation-item {
    .ui.grid.transformation-grid {
      .transformation-image-column {
        padding: 1rem 0.5rem;
        img {
          height: 100%;
        }
      }
      .main-content {
        h2 {
          font-size: 16px;
        }
        .user-avatar {
          img {
            display: none;
          }
        }
        .header {
          padding-bottom: 0.5rem;
        }
        .description {
          padding-top: 0.5rem;
        }
      }
      .transformation-likes {
        img.bookmark {
          max-width: 30px;
        }

        img {
          max-width: 25px;
        }

        .bookmark-row {
          padding-bottom: 0.25em;
        }

        .crowdsourcing-row {
          padding-top: 0.5em;
          .crowdsourcing-grid {
            align-items: center;
            flex-direction: column;

            .column {
              width: 100%;
            }
          }
        }

      }
    }
  }
}