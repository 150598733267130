@use 'sass:map';
.ui-text {
  color: $ui-text-color;
}

.ui-header {
  color: $ui-header-text-color;
}

.login-page {
  padding-top: 104px;
  padding-bottom: 104px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-origin: border-box;
  background-image: $bg-img_blob3;
  background-repeat: no-repeat;
  background-position: center bottom -744px;

  .login-container {
    display: flex;
    justify-content: center;

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 550px;
      //background-color: $primary-white;
      padding: 4em 3em;

      .login-symbol {
        width: 80px;
      }

      .hero-text {
        text-align: center;
      }

      .form-fields {
        width: 100%;
      }

      .links {
        width: 100%;

        a {
          display: block;
        }
      }
    }
  }
}

@media (max-width: map.get($breakpoints, lg)) {
  .login-page {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media (max-width: map.get($breakpoints, md)) {
  .login-page {
    padding: 5rem 2rem 6rem;
    background-position: center bottom -800px;
  }
}

@media (max-width: map.get($breakpoints, sm)) {
  .login-page {
    padding-top: 3rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    h1.ui-header {
      font-size: 24px;
    }
    .login-container {
      .login-form {
        padding: 2.5em 2em;
      }
    }
  }
}

