@use "sass:map";
.resource-management {
  .edit-icon-column {
    color: $ui-neutral-600;

    svg {
      transition: scale 100ms;
    }

    svg:hover {
      scale: 1.1;
      cursor: pointer;
    }
  }

  .ui.pagination.resource-pagination-menu {
    .active.item {
      background-color: $primary-white;
      color: $transformar-blue;
      font-weight: bold;
    }

    a.item:hover {
      background-color: $ui-hover-menu-item;
      color: $transformar-blue;
    }
    @media (max-width: map.get($breakpoints, sm)) {
      a.item {
        display: none;
      }
      a.item.icon {
        display: flex;
      }
    }
  }

  .new-resource-btn {
    display: block;
    margin-left: auto;
  }
}

.resource-modal-form {

  .resource-modal__form {
    margin: 0 auto;
    max-width: 650px;
    display: flex;
    flex-direction: row;
    width: 100%;

    .form_group {
      width: 100%;
      padding: 1em;
    }
  }
}

